var exports = {};
var Sister;
/**
* @link https://github.com/gajus/sister for the canonical source repository
* @license https://github.com/gajus/sister/blob/master/LICENSE BSD 3-Clause
*/

Sister = function () {
  var sister = {},
      events = {};
  /**
   * @name handler
   * @function
   * @param {Object} data Event data.
   */

  /**
   * @param {String} name Event name.
   * @param {handler} handler
   * @return {listener}
   */

  sister.on = function (name, handler) {
    var listener = {
      name: name,
      handler: handler
    };
    events[name] = events[name] || [];
    events[name].unshift(listener);
    return listener;
  };
  /**
   * @param {listener}
   */


  sister.off = function (listener) {
    var index = events[listener.name].indexOf(listener);

    if (index !== -1) {
      events[listener.name].splice(index, 1);
    }
  };
  /**
   * @param {String} name Event name.
   * @param {Object} data Event data.
   */


  sister.trigger = function (name, data) {
    var listeners = events[name],
        i;

    if (listeners) {
      i = listeners.length;

      while (i--) {
        listeners[i].handler(data);
      }
    }
  };

  return sister;
};

exports = Sister;
export default exports;